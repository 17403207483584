import React, { useState, useEffect } from 'react';
import './css/JurryFourm.css';

function JurryFourm(props) {
  const jurry = props.jurry;
  const setJurry = props.setJurry;
  const parentElement = props.parentElement
  const [defultJurryNation, setdefultJurryNation] = useState(parentElement === "USSAFourm" ? 'CAN' : 'USA');
  const [tmpJurry, setTmpJurry] = useState({"FirstName":null, "LastName":null, "Nation":`${defultJurryNation}`, "Function":"CHIEFCOMPETITION"});
  const [addJurryVisable, setAddJurryVisable] = useState(false);
  const nations = [
    "AUS","AUT","BEL","BUL","CAN","CHI","CHN","CRO","CZE","DEN","ESP","EST","FIN","FRA","GBR","GER","GRE","HUN","IRL","ISL","ISR","ITA","JPN","KAZ","KOR","LAT","LIE","LTU","LUX","MEX","MGL","MON","NED","NOR","NZL","POL","POR","ROU","RSA","RUS","SLO","SUI","SVK","SWE","TUR","UKR","USA","UZB",
  ];
  useEffect(() => {
    if (props.headerInfo && props.headerInfo.nation) {
      console.log(props.headerInfo.nation);
      //defultJurryNation = props.headerInfo.nation;
    } else {
      console.log("headerInfo or nation is not available yet.");
    }
  }, [props.headerInfo]);

  // make the add jurry pannle visable or not
  const JurryVisable = () => {
    setAddJurryVisable(jurryVisable => !jurryVisable);
  }

  // add the jurry member to the list of jurry members
  const addJurry = () => {
    setJurry( (prevState) => [...prevState, tmpJurry]); // add the tmpJurry to the list of Jurrys
    setdefultJurryNation(tmpJurry.Nation)
    setTmpJurry({"FirstName":null, "LastName":null, "Nation":`${defultJurryNation}`, "Function":"CHIEFCOMPETITION"}); // set tmpJurry to be empty
    setAddJurryVisable(false); // make the add jurry pannle invisable
  }

  // handle input change for the jurry
  const handleJurryInputChange = (event) => {
    const { id, value } = event.target;
    setTmpJurry(prevState => ({ ...prevState, [id]: value })); // set the tmpJurry to the values in the input fields
  };

  // delete the jurry member from the list of jurry members
  const deleteJurryMember = (index) => {
    setJurry(jurry.filter((juryMember, i) => i !== index));
  }

  useEffect(() => {
    // save the header info to local storage
    let storageName = parentElement === "USSAfourm" ? ('USSAStoredJury'):('FISStoredJury')
    localStorage.setItem(storageName, JSON.stringify(jurry));
  }, [jurry]);
  
  return (
    <>
      {/* jurry input */}
      <div>
        {/* display jurry members */}
        <form id="Jurry-Form" onSubmit={(e) => e.preventDefault()}>
          {jurry && (jurry.length) === 0 ? (
            <p id="defultJurryMessage" className="jurryList">
              No Jurry Members
            </p>
          ) : (
            <table className="jurryTable">
              <thead>
                <tr>
                  <th>Function</th>
                  <th>Name</th>
                  <th>Nation</th>
                  {parentElement === "USSAfourm" ? (
                    <th>Member #</th>
                  ):('')}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {jurry && jurry.map((jurryMember, index) => (
                  <tr key={jurryMember.FirstName}>
                    <td>{jurryMember.Function}</td>
                    <td>
                      {jurryMember.FirstName} {jurryMember.LastName}
                    </td>
                    <td>{jurryMember.Nation}</td>
                    {parentElement === "USSAfourm" ? (
                      <td>{jurryMember.MemberNumber}</td>
                    ):('')}
                    <td>
                      <button
                        className="deleteJurry"
                        onClick={() => deleteJurryMember(index)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <button onClick={JurryVisable}>Add Jury</button>
          {addJurryVisable && (
            <div id="addJurryDropDown">
              <div className='outerDiv'>
                <table>
                  <tbody>
                  {/* Function */}
                  <tr>
                    <td>
                      <label htmlFor="Function">Function: </label>
                    </td>
                    <td>

                      <select onChange={handleJurryInputChange} id="Function">
                      {parentElement === "USSAfourm" ? (
                        <>
                          <option value="CHIEFCOMPETITION">Chief Competition</option>
                          <option value="TECHNICALDELEGATE">Technical Delegate</option>
                          <option value="RACEDIRECTOR">Race Director</option>
                          <option value="RACEDIRECTORASSISTANT">Race Director Assistant</option>
                          <option value="ASSISTANT">Technical Delegate Assistant</option>
                        </>
                      ) : (
                          // Add other options here if needed for different parentElement values
                          <>
                          <option value="CHIEFCOMPETITION">Chief Competition</option>
                          <option value="TECHNICALDELEGATE">Technical Delegate</option>
                          <option value="RACEDIRECTOR">Race Director</option>
                          <option value="RACEDIRECTORASSISTANT">Race Director Assistant</option>
                          <option value="TECHNICALDELEGATEASSISTANT">Technical Delegate Assistant</option>
                          <option value="TECHNICALDELEGATEASSISTANTNATIONAL">Technical Delegate Assistant National</option>
                          <option value="MEMBER">Member</option>
                          </>
                      )}
                      </select>

          
                    </td>
                  </tr>

                  {/* Fist Name */}
                  <tr>
                    <td>
                      <label htmlFor="FirstName">First Name: </label>
                    </td>
                    <td>
                      <input
                        type="text"
                        id="FirstName"
                        onChange={handleJurryInputChange}
                      ></input>
                    </td>
                  </tr>

                  {/* Last Name */}
                  <tr>
                    <td>
                      <label htmlFor="LastName">Last Name: </label>
                    </td>
                    <td>
                      <input
                        type="text"
                        id="LastName"
                        onChange={handleJurryInputChange}
                      ></input>
                    </td>
                  </tr>

                  {/* Nation */}
                  <tr>
                    <td>
                      <label htmlFor="Nation">Nation: </label>
                    </td>
                    <td>
                      <select
                        id="Nation"
                        defaultValue={defultJurryNation}
                        onChange={handleJurryInputChange}
                      >
                        {nations.map((nation) => (
                          <option key={nation} value={nation}>
                            {nation}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>

                  {/* Member # */}
                  {parentElement === "USSAfourm" ? (
                    <tr>
                      <td>
                        <label htmlFor="MemberNumber">Member #: </label>
                      </td>
                      <td>
                        <input
                          id="MemberNumber"
                          onChange={handleJurryInputChange}
                        >
                        </input>
                      </td>
                    </tr>
                  ):('')}

                </tbody>
                </table>
                  <div id="jurry-add">
                      <button className="smallButton" onClick={addJurry}>
                        Add
                      </button>
                  </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
}

export default JurryFourm;