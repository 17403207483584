import React, { useEffect, useState } from "react";
import "./css/DataCleanUp.css";
import LoadingSpinner from './LoadingSpiner';
import CalculatePoints from "./CalculatePoints";
import NewCSVPopup from "./NewCSVPopup";

function FISDataCleanUp(props) {
    const csvData = props.csvData;
    const setCsvData = props.setCsvData;
    const headerInfo = props.headerInfo;
    const setHeaderInfo = props.setHeaderInfo;


    // STATES
    const [isLoading, setIsLoading] = useState(false);
    // popup for showing the changes that will be made to the csv data from the fis DB
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const  originalData = headerInfo.originalData;

    // FUNCTIONS
    const handlePopupClose = () => {
        setIsPopupOpen(false);
        setPopupData(null);
    };

    const checkForNonFISLicenceRacers = () =>{
        let unLicencedRacers = []
        //This is the first step in the FIS cleaning routine
        // go through the csvData and make sure every racer has a FIScode.
        //Dan - I don't think unLicencedRacers is used anywhere
        csvData.forEach((row) => {
            if(!row["FIScode"]){
                unLicencedRacers.push(row)
            }
        })

        // remove the racers from the results
        let updatedCsvData = csvData.filter(racer => racer["FIScode"])
        // now update the ranking for the updatedCSVData based on the time. if theyhvae the same time then give them the same rank
        let updatedRank = 1
        let lastTime = 0
        let skipRankFromTie = 0
        updatedCsvData.forEach((racer, idx) => {
            if(racer['status'] === '' || racer['status'] === 'QLF'){
                if(racer["time"] !== lastTime){
                    racer["rank"] = updatedRank + skipRankFromTie
                    // skipRankFromTie = 0
                    updatedRank += 1
                }
                else{
                    racer["rank"] = updatedRank - 1 + skipRankFromTie
                    skipRankFromTie += 1
                }
                lastTime = racer["time"]
            }
        })

        return updatedCsvData
    }

    const checkForMissingRacers = async (data, licenceList) => {
        //Data is the JSON we got back from FISPoints lambda and licenceList is the list of all FISLicences in our race.
        
        //The first part is a sub functions for parsing the returned XML results the other lambda that gets called 
        //inside this parent function below
        const parseXMLString = (xmlString) => {
            let result = {};

            if (!xmlString) {
                console.error('Received undefined or null XML string');
                return null;
            }

            // Splitting XML string based on tags
            const tags = xmlString.split('<');

            for(let i = 0; i < tags.length; i ++){
                if(tags[i].includes('sectorcode="CC"')){
                    for( let j = i; j < tags.length; j++){
                        //  if its not a closing tag
                        const tagContent = tags[j].split('>')[1];
                        if (tagContent && !tagContent.includes('\n')) {
                            if (tags[j].includes('fiscode>')) {
                                result['fiscode'] = tagContent;
                            }
                            if (tags[j].includes('lastname>')) {
                                result['lastname'] = tagContent;
                            }
                            if (tags[j].includes('firstname>')) {
                                result['firstname'] = tagContent;
                            }
                            if (tags[j].includes('gender>')) {
                                result['gender'] = tagContent;
                            }
                            if (tags[j].includes('nationcode>')) {
                                result['nationcode'] = tagContent;
                            }
                            if (tags[j].includes('year>')) {
                                result['year'] = tagContent;
                            }
                            if (tags[j].includes('status>')) {
                                result['status'] = tagContent;
                            }
                            if (result['fiscode'] && result['lastname'] && result['firstname'] && result['gender'] && result['nationcode'] && result['year'] && result['status']) {
                                break;
                            }
                        }
                    }
                } 
            }
            return Object.keys(result).length > 0 ? result : null;

        }

        //This is the **actual** start of this function from a readability standpoint
        //Data is the JSON we got back from FISPoints lambda and licenceList is the list of all FISLicences in our race.
        //Push all the returned FISCodes from the JSON onto a list of knownRacers - there were found in the FISpoints list
        let knownRacers = [];
        (data || []).forEach((racer) => {
            knownRacers.push(racer['fis_licence'])
        })
        // take the full licenceList of every licence in our race and filter out the known racers returned from the api
        //- leaving us with FIScodes that are NOT in the current FIS points list
        const FIScodes = licenceList.filter(racer => !knownRacers.includes(racer))
        console.log('122: Not known people:', FIScodes)

        // We then want to hit the CORS proxi/website api for them and add thei names, country and yob, and make sure they are active
        if(FIScodes.length > 0) {
            await new Promise(resolve => setTimeout(resolve, 1000));

            console.log('127: hitting CORS proxy api for racers:', FIScodes)
            //This is the CORS proxy to hit FIS website to check current status and names of people who aren't in the current list
            //This returns a JSON dict of the raw XML from the FIS website
            let apiUrl = 'https://p474fmngovil3jmfzxh75fuvg40ksjuf.lambda-url.us-west-2.on.aws/'
            console.log('129:' + apiUrl)

            try{
                // Send a GET request to the API endpoint using fetch
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ FIScodes }),
                });      
    
                if (!response.ok) {
                    throw new Error(`142: HTTP error! Status: ${response.status}`);
                }
            
                // Parse the XML response
                const returnedFISData = await response.json()
                FIScodes.forEach((FISID) => {  
                    if(!returnedFISData[FISID]){
                        console.log('149: NO FIS XML FOR:', FISID)
                        console.log('150: NO FIS XML Data FOR:', returnedFISData[FISID])
                    }
                    //Take the XML and convert it to a JSON junk (if CCSector data)
                    let parsedData = parseXMLString(returnedFISData[FISID])
                    if(parsedData){
                        console.log(FISID + 'status=' + parsedData['status'])
                        //console.log('164: FIS XML Data FOR:', returnedFISData[FISID])
                        /* The current structure variable: 'data' is:
                        [
                            {
                                "competior_id": "225619",
                                "fis_licence": "3195319",
                                "last_name": "DUCHAUFOUR",
                                "first_name": "Eve Ondine",
                                "gender": "W",
                                "date_of_birth": "2001-10-04",
                                "nation": "FRA",
                                "fis_points": {
                                    "DI": "95.34",
                                    "SP": "148.32"
                                }
                            },
                        */
                        //Push them onto the data structure including STATUS so we can clean the CSV including their data 
                        //and erase them from the CSV if they are inactive
                        let personInfo = {}
                        personInfo['competior_id'] = null
                        personInfo['fis_licence'] = parsedData['fiscode']
                        personInfo['last_name'] = parsedData['lastname']
                        personInfo['first_name'] = parsedData['firstname']
                        personInfo['gender'] = parsedData['gender']
                        personInfo['date_of_birth'] = parsedData['year']
                        personInfo['nation'] = parsedData['nationcode']
                        personInfo['fis_points'] = {'DI': null, 'SP': null}  //Can't have any points if parsing from website
                        personInfo['status'] = parsedData['status']  //Include status so we can clean the CSV if inactive later
                        data.push(personInfo) //Append them to the data structure
                    }
                })
    
            
               } catch (error) {
                     console.error('Error fetching data for FIS racers from FIS information lambda', error.message);
               }
            //Finally return data which is the origional data returned from the first points API call along with the 
            //website records appended at the bottom - the only difference in the records is the website ones will have a status 
            //property whereas the points ones will not
            return data
        } else {
            return data
        }
    }

/*
            // hit the fis api for each fis list untill it returns one
            //let apiUrl = `https://www.fis-ski.com/DB/general/search.html?search=${notKnownRacers[i]}&feed=false&byfiscode=true`
            let apiUrl = 'https://p474fmngovil3jmfzxh75fuvg40ksjuf.lambda-url.us-west-2.on.aws/'
            console.log(apiUrl)

            //try {
                // Send a GET request to the API endpoint using fetch
                const response = await fetch(apiUrl);
                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }
            
                // Parse the XML response
                const xmlData = await response.text()
                console.log('parsing xml for racer:', notKnownRacers[i])
                let parsedData = await parseXMLString(xmlData)
                console.log('xml parse result:', parsedData)

                if(parsedData){
                    // this is the person to add
                    let personInfo = {}
                    personInfo['first_name'] = parsedData['firstname']
                    personInfo['last_name'] = parsedData['lastname']
                    personInfo['gender'] = parsedData['gender']
                    personInfo['nation'] = parsedData['nationcode']
                    personInfo['fis_licence'] = parsedData['fiscode']
                    personInfo['date_of_birth'] = parsedData['year']
                    personInfo['fis_points'] = {'DI': null, 'SP': null}
                    personInfo['status'] = parsedData['status']
                    data.push(personInfo)
                }
            
              //} catch (error) {
              // console.error(`Error fetching data for racer ${notKnownRacers[i]}:`, error.message);
              //}
        }
        return data
    }
*/
    //This is the entry point for the cleanup routines for FIS
    const getFisData = () => {
        console.log('230: getFisData started')
        setIsLoading(true);

        //The following iterates through the csvData structure and removes any racers that don't have a FIS licence
        //The returned json structure is a dict with the licence holders removed
        let newCSVData = checkForNonFISLicenceRacers()
        console.log('235 length of newCSVData=' + newCSVData.length) //22

        //Make a list of all the FIS licences in the remaining CSV data and save it to variable 'racers'
        var racers = [];
        (newCSVData || csvData).forEach((row) => {
            if(row["FIScode"]){
                racers.push(row["FIScode"]);
            }
        });
        

        var points_list = parseInt(headerInfo['usedFISList']);

        //Send that list of FIS codes 
        console.log('249 length of racers=' + racers.length)  //22

        const data_to_send = new FormData();
        data_to_send.append('points_list', points_list);
        data_to_send.append('racers', racers);  //This list of FISlicences assembled above
        data_to_send.append('return_csv', false);

        /*
        Call FIS Lambda that looks up list of FIS codes in the latest points list and returns JSON dict that looks like:
        [
            {
                "competior_id": "225619",
                "fis_licence": "3195319",
                "last_name": "DUCHAUFOUR",
                "first_name": "Eve Ondine",
                "gender": "W",
                "date_of_birth": "2001-10-04",
                "nation": "FRA",
                "fis_points": {
                    "DI": "95.34",
                    "SP": "148.32"
                }
            },
        */
        try{
            fetch("https://ggpwjy2pju3cttzzmoxhnuidyi0bkuwd.lambda-url.us-west-2.on.aws", {
            method: 'POST',
            body: data_to_send
            })
            .then(response => response.json())
            .then(data => {
                if(data["message"] === undefined){
                    console.log ("287 data=", data)
                    //Now not everyone will be found in the latest points list
                    //For FIS licences that aren't found in the current points list, we need to look them up on FIS website
                    //to ensure they are active
                    //We're sending the same list of FISlicences we assembed above - our current list of FISLicences in the CSV File
                    //along with the JSON we got back from the FISPoints lambda function api call we made above, 
                    //And we're sending it to this function checkForMissingRacers
                    //this function appends more records to the data structure which it gets from the lookup of website records
                    checkForMissingRacers(data, racers)
                    .then((result)=>{
                        //result should be just like data along with the website records
                        console.log("results returned from checkForMissingRacers", result);
                        //Now that we have a completed data set to work from, send it to changeCSVData
                        newCSVData = changeCSVData(result, newCSVData);
                        console.log('301: newCSVData after=' + newCSVData.count)
                        setIsLoading(false);
                        setPopupData(newCSVData);
                        setIsPopupOpen(true);
                    })
                    .catch((err)=>{
                        console.warn('Error:', err)
                    })
                }
                else{
                    window.alert(data["message"]);
                    setIsLoading(false);
                }
            })
        } catch (error) {
            console.error('Error fetching data for FIS racers from FIS points lambda', error.message);
        }
    }

    const changeCSVData = (data, newCSVData) => {
        //Data shoud be just the json dict from both API lookups

        const checkNames = (apiRow, row) => {
            // if they are the same then set the first name and last name to the values from the api
            if(row["firstname"] !== apiRow["first_name"]){
                row["firstname"] = apiRow["first_name"]
            };

            if(row["lastname"] !== apiRow["last_name"]){
                row["lastname"] = apiRow["last_name"]
            };
        }

        const checkFISPoints = (apiRow, row) => {
            // if they are the same then set the FISpoints to the values from the api
            if(row["FISpoints"] !== apiRow["fis_points"][headerInfo["discipline"]]){
                row["FISpoints"] = apiRow["fis_points"][headerInfo["discipline"]]
            };
        }

        const addFISPoints = (apiRow, row) => {
            // if they are the same then set the FISpoints to the values from the api
            let points = null
            try{
                points = parseFloat(apiRow["fis_points"][headerInfo["discipline"]])
            } catch(err) {
                points = null
            }
            row["FISpoints"] = points;
            return 1;
        }

        const checkNationality = (apiRow, row) => {
            if(row["Country"] !== apiRow['nation']){
                row["Country"] = apiRow["nation"]
                return 1;
            }
            return 0;
        }

        const checkYOB = (apiRow, row) => {
            if(row["YOB"] !== apiRow['date_of_birth'].split("-")[0]){
                row["YOB"] = apiRow['date_of_birth'].split("-")[0];
                return 1;
            }
            return 0;
        }

        const addGender = (apiRow, row) => {
            row['sex'] = apiRow['gender'];
        }

        var add_nation = 0;
        var add_YOB = 0;
        var add_fis_points = 0;
        
        //Data shoud be just the json dict from both API lookups
        console.log('375 data=', data)
        //For each of the racers that have a FIS licence
        newCSVData.forEach((row) => {
            //data came from CORS proxy FIS website api hit - it has status but no points
            data.forEach((apiRow) => {
                // if licence matches check if the first name and last name are the same
                //console.log('comparing row["FIScode"]=', row["FIScode"], 'with apiRow["fis_licence"]:', apiRow["fis_licence"])
                if(row["FIScode"] === apiRow["fis_licence"]){
                    //console.log('matching FIScode:', row["FIScode"], 'with apiRow["fis_licence"]:', apiRow["fis_licence"])
                    // check the names
                    checkNames(apiRow, row);
                    // add gender from fis data
                    addGender(apiRow, row);

                    // if there are FISpoints in the row then check them. else add them
                    if(row["FISpoints"]){
                        checkFISPoints(apiRow, row);
                    } else{
                        add_fis_points += addFISPoints(apiRow, row);
                    }
                    // check nationality of racer
                    add_nation += checkNationality(apiRow, row);
                    
                    // check YOB of racer
                    add_YOB += checkYOB(apiRow, row);
                    console.log('apiRow["status"]=', apiRow["status"])
                    if(apiRow["status"] === 'E'){
                        // if the status is E then remove the racer from the CSV
                        console.log('removing racer:', row["FIScode"])
                        newCSVData = newCSVData.filter(racer => racer["FIScode"] !== row["FIScode"]);

                        let updatedRank = 1
                        let lastTime = 0
                        let skipRankFromTie = 0
                        newCSVData.forEach((racer, idx) => {
                            if(racer['status'] === '' || racer['status'] === 'QLF'){
                                if(racer["time"] !== lastTime){
                                    racer["rank"] = updatedRank + skipRankFromTie
                                    // skipRankFromTie = 0
                                    updatedRank += 1
                                }
                                else{
                                    racer["rank"] = updatedRank - 1 + skipRankFromTie
                                    skipRankFromTie += 1
                                }
                                lastTime = racer["time"]
                            }
                        })
                    }
                }
            });
        });
        let headers = headerInfo.headers;
        if(add_nation > 0 && headers.includes('Country') === false){
            headers.push('Country');
            setHeaderInfo((prevState) => ({
                ...prevState,
                "headers":headers
            }));
        }
        if(add_YOB > 0 && headers.includes('YOB') === false){
            headers.push('YOB');
            setHeaderInfo((prevState) => ({
                ...prevState,
                "headers":headers
            }));
        }
        if(add_fis_points > 0 && headers.includes('FISpoints') === false){
            headers.push('FISpoints');
            setHeaderInfo((prevState) => ({
                ...prevState,
                "headers":headers
            }));
        }

        // setCsvData(newCSVData);
        return newCSVData;
    }

    // calculate the top 5 racers with the lowest FISpoints. this is hard coded and needs to change depending on the fild of the csv.
    const calculateTop5Racers = () => {
    const sortedPeople = [...csvData].sort(
      (a, b) => a["FISpoints"] - b["FISpoints"]
    );
    
    const filteredRacers = sortedPeople.filter(
      racer => racer.rank >= 1 && racer.rank <= 5
    );
    
    const sortedFilteredRacers = [...filteredRacers].sort(
      (a, b) => a.rank - b.rank
    );
    // set the header info wutht he top 5 racers
    setHeaderInfo((prevState) => ({
        ...prevState,
        "top5Racers":sortedFilteredRacers
        }));
    } 

    // make the popup visable or not
    const ToggleCalculatePenalty = () => {
    // create popup for calculating penalty
    const overlay = document.getElementById('overlay');
    if (overlay.style.display === 'flex') {
      overlay.style.display = 'none';
    }
    else {
      overlay.style.display = 'flex';
      calculateTop5Racers();
    }

    }

    useEffect(() => {
        console.log('original data changed:', originalData)
    }, [originalData])


  return (
    <>
      <div id="dataCleanUp">
        <div id="dataCleanUpBox">
          <div id="instr">
            Once you have imput all the correct info for the Header Section. you
            can then calculate the FIS points for the race if they do not have
            any. First you can check the data agenst the FIS database. To do so
            use the button below. Then you can calculate the FIS points.
          </div>
          <button id="checkData" onClick={getFisData} disabled={isLoading || !csvData || !(csvData.length > 0)}>
            {isLoading ? <LoadingSpinner /> : "Check Data against FIS Data"}
          </button>
          <button id="calcAndTog" onClick={ToggleCalculatePenalty} disabled={!csvData || !(csvData.length > 0) || headerInfo.format === 'sprintFinal'}>
            Calculate Penalty / Race Points
          </button>
        </div>

        <div id="calculate-points">
            <CalculatePoints headerInfo={headerInfo} setHeaderInfo={setHeaderInfo} csvData={csvData} setCsvData={setCsvData} ToggleCalculatePenalty={ToggleCalculatePenalty}/>
        </div>
      </div>

      <NewCSVPopup
        isPopupOpen={isPopupOpen}
        closeWindow={handlePopupClose}
        newData={popupData}
        oldData={originalData}
        headerInfo={headerInfo}
        headers={headerInfo.headers}
        setCsvData={setCsvData}
        setHeaderInfo={setHeaderInfo}
      />

    </>
  );
}

export default FISDataCleanUp;