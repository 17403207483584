import React, {useEffect, useState} from "react";
import LoadingSpinner from "./LoadingSpiner";
import {createUSSAXML} from "./createUSSAxml";

function MakeUSSAXML(props) {
  const data = props.csvData;
  const jurry = props.jurry;
  const headerInfo = props.headerInfo;

  const [xmlURL, setxmlURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /**
  const checkForCorectSex = () =>{
     
     go through the racers and find the sex. then match that sex to the 
     one selected in the header info. if they dont match then allert the 
     user to change the sex for the file.
    
    console.log('data trying to find sex:', data)
    let raceSex = '';
    for (let racer of data) {
      if (racer['sex']) {
        raceSex = racer['sex'];
        console.log('raceSex=' + raceSex);
        break; // Exit the loop once the first valid sex is found
      }
    }
    if (!raceSex) {
      raceSex = 'M'; // Default to 'M' if no valid sex is found
    }
    let headerInfoSex = headerInfo.sex
    console.log ('raceSex=' + raceSex + ' & headerInfoSex=' + headerInfoSex)
    return raceSex === headerInfoSex
  }
**/ 

  // downloads the XML file
  const DownloadXML = () => {
    //if(!checkForCorectSex()){
    //  window.alert('Sex in the header info does not match the sex of the racers in the File you uploaded')
    //  return
    //}
    console.log ('here 1')
    setIsLoading(true);
    console.log ('download the XML file')
    createUSSAXML(headerInfo, data, jurry, setxmlURL);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  // helper for downloading the xml
  useEffect(() => {
    const handleDownload = () => {
      // Use the HTMLAnchorElement to download the file
      const downloadLink = document.createElement("a");
      downloadLink.href = xmlURL;
      downloadLink.download = `${headerInfo.NRC || "USSA_XML_Results"}.xml`;
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);
      document.getElementById('hiddenDownloadLinkUSSA').appendChild(downloadLink);
      downloadLink.click()

      setxmlURL("")
    };
    if (data && data.length > 0 && xmlURL) {
      handleDownload();
    }
  }, [xmlURL, data, headerInfo.title]);

  return (
    <>
      <button id="downloadButton" onClick={DownloadXML} disabled={!data || !(data.length > 0)}>
        {isLoading ? <LoadingSpinner /> : "Download XML"}
      </button>
      <div id='hiddenDownloadLinkUSSA' style={{display:"none"}}></div>
    </>
  );
}

export default MakeUSSAXML;
