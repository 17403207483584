/*
    NOTE: NOT DONE!
    Create XML string here for USSA
    this is a really big function. 
    could have definitely been broken up into smaller functions 
    but it works
*/

export function createUSSAXML(headerInfo, data, jurry, setxmlURL) {
    function formatXml(sourceXml) {
        sourceXml = sourceXml.replace(/\t/g, '');
        var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
        var xsltDoc = new DOMParser().parseFromString([
            // describes how we want to modify the XML - indent everything
            '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
            '  <xsl:strip-space elements="*"/>',
            '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
            '    <xsl:value-of select="normalize-space(.)"/>',
            '  </xsl:template>',
            '  <xsl:template match="node()|@*">',
            '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
            '  </xsl:template>',
            '  <xsl:output indent="yes" spaces="8"/>',
            '</xsl:stylesheet>',
        ].join('\n'), 'application/xml');
    
        var xsltProcessor = new XSLTProcessor();    
        xsltProcessor.importStylesheet(xsltDoc);
        var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
        var resultXml = new XMLSerializer().serializeToString(resultDoc);
        return resultXml;
    }
    
    const makeHeaderString = () => {
        const dateNeeded = headerInfo.raceDate.split("-");
        let season = dateNeeded[0]

        // if the season is grater then june (06) then put the season + 1
        if (parseInt(dateNeeded[1]) > 8) {
            season = parseInt(season) + 1
        }

        const xmlString = `\t<Raceheader Sector="CC" Gender="${headerInfo.sex || null}">
    \t\t<Season>${season || ""}</Season>
    \t\t<Codex>${headerInfo.codex || ""}</Codex>
    \t\t<NAT_code>${headerInfo['NRC'] || ""}</NAT_code>
    \t\t<Nation>${headerInfo.nation || ""}</Nation>
    \t\t<Discipline>${headerInfo.discipline || ""}</Discipline>
    \t\t<Event_type>${headerInfo.eventType || ""}</Event_type>
    \t\t<Technique>${headerInfo.technique || ""}</Technique>
    \t\t<Distance>${headerInfo.distance || ""}</Distance>
    \t\t<Category>${headerInfo.category || ""}</Category>
    \t\t<Racedate>
    \t\t\t<Day>${dateNeeded[2] || ""}</Day>
    \t\t\t<Month>${dateNeeded[1] || ""}</Month>
    \t\t\t<Year>${dateNeeded[0] || ""}</Year>
    \t\t</Racedate>
    \t\t<Eventname>${headerInfo.eventName || ""}</Eventname>
    \t\t<Place>${headerInfo.place || ""}</Place>
    \t\t<Clubname>${headerInfo.clubName || ""}</Clubname>
    ${
        jurry
            .filter(jurryMember => jurryMember['Function'] === "TECHNICALDELEGATE")
            .map(jurryMember => `
                \t\t<TD Function="DELEGATE">
                \t\t\t<Tdnumber>${jurryMember.tdNumber || ''}</Tdnumber>
                \t\t\t<Tdlastname>${jurryMember.LastName || ''}</Tdlastname>
                \t\t\t<Tdfirstname>${jurryMember.FirstName || ''}</Tdfirstname>
                \t\t\t<Tdnation>${jurryMember.Nation || ''}</Tdnation>
                \t\t\t<NAT_tdnum>${jurryMember.MemberNumber || ''}</NAT_tdnum>
                \t\t</TD>`
            )
            .join('\n')
    }
    \t</Raceheader>`;
        return xmlString;
    };
    
    const makeRaceInfoString = () => {
        const makeJurryString = (Jurry) => {
        const xmlString = `\t\t<Jury Function="${Jurry["Function"]}">
    \t\t\t\t<Jurylastname>${Jurry["LastName"]}</Jurylastname>
    \t\t\t\t<Juryfirstname>${Jurry["FirstName"]}</Juryfirstname>
    \t\t\t\t<Jurynation>${Jurry["Nation"]}</Jurynation>
    \t\t\t\t<NAT_num>${Jurry["MemberNumber"]}</NAT_num>
    \t\t\t</Jury>`;
        return xmlString;
        };

        const jurryStrings = [];
        jurry
        .filter(jurryMember => jurryMember['Function'] !== "TECHNICALDELEGATE")
        .forEach((jurry) => {
            const xmlString = makeJurryString(jurry);
            jurryStrings.push(xmlString);
        });

        const xmlString = `\t\t${jurryStrings.join("\n")}${jurryStrings.length > 0 ? "\n\n" : ""}
    \t\t<CC_raceinfo>
    \t\t\t<Usedfislist>${headerInfo.usedFISList || ''}</Usedfislist>
    \t\t\t<NAT_list>${headerInfo.usedNRLList || ''}</NAT_list>
    \t\t\t<Appliedpenalty>${headerInfo.penalty || ''}</Appliedpenalty>
    \t\t\t<Calculatedpenalty>${headerInfo.calculatedPenalty || ''}</Calculatedpenalty>
    \t\t\t<NAT_appliedpenalty>${headerInfo.penalty || ''}</NAT_appliedpenalty>
    \t\t\t<NAT_calculatedpenalty>${headerInfo.calculatedPenalty || ''}</NAT_calculatedpenalty>
    \t\t\t<Fvalue>${headerInfo.sugestedFFactor || ""}</Fvalue>
    \t\t\t<State>${headerInfo.state || ""}</State>
    \t\t\t<Homologation>${headerInfo.homologation || ""}</Homologation>
    \t\t</CC_raceinfo>
    `;

        return xmlString;
    };

    // results for individual distance and sprint qualification 
    const makeRacersString = () => {
        // helper function to make the XML for a single racer
        const makeSingleRacerString = (row, idx) => {
        let xmlString = `\t\t${idx === 1 ? ('') : ('\t')}<CC_ranked Status="${row["status"]}">
    \t\t\t\t<Rank>${row["rank"] || ''}</Rank>
    \t\t\t\t<Bib>${row["Racer #"] || ''}</Bib>
    \t\t\t\t<Competitor>
    \t\t\t\t\t<Fiscode>${row["FIScode"] || '9999999'}</Fiscode>
    \t\t\t\t\t<Lastname>${row["lastname"] || ''}</Lastname>
    \t\t\t\t\t<Firstname>${row["firstname"] || ''}</Firstname>
    \t\t\t\t\t<Sex>${row["sex"] || headerInfo.sex}</Sex>
    \t\t\t\t\t<Nation>${row["Country"] || ''}</Nation>
    \t\t\t\t\t<Yearofbirth>${row["YOB"] || ''}</Yearofbirth>
    ${row['USSAcode'] ? (`\t\t\t\t\t<NAT_code>${row["USSAcode"] || ''}</NAT_code>`) : ('')}
    \t\t\t\t\t<penalty_points>${
        (row["NRLpoints"] && row["NRLpoints"] !== 990)
            ? row["NRLpoints"]
            : row["FISpoints"] || '990'
    }</penalty_points>
    \t\t\t\t</Competitor>
    \t\t\t\t<CC_result>
    \t\t\t\t\t<Totaltime>${row["time"] || ''}</Totaltime>
    \t\t\t\t\t<Racepoints>${row["Race Points"] || ''}</Racepoints>
    \t\t\t\t\t<Level>Final</Level>
    \t\t\t\t</CC_result>
    \t\t\t</CC_ranked>`;
        return xmlString;
        };

        const makeSingleRacerStringNotQLF = (row) => {
        let xmlString = `\t\t\t<CC_notranked Status="${row["status"]}">
    \t\t\t\t<Bib>${row["Racer #"] || ""}</Bib>
    \t\t\t\t<Competitor>
    \t\t\t\t\t<Fiscode>${row["FIScode"] || '9999999'}</Fiscode>
    \t\t\t\t\t<Lastname>${row["lastname"] || ''}</Lastname>
    \t\t\t\t\t<Firstname>${row["firstname"] || ''}</Firstname>
    \t\t\t\t\t<Sex>${row["sex"] || ''}</Sex>
    \t\t\t\t\t<Nation>${row["Country"] || ''}</Nation>
    \t\t\t\t\t<Yearofbirth>${row["YOB"] || ''}</Yearofbirth>
    ${row['USSAcode'] ? (`\t\t\t\t\t<NAT_code>${row["USSAcode"] || ''}</NAT_code>`) : ('')}
    \t\t\t\t\t<penalty_points>${row["FISpoints"] || row['NRLpoints'] || '990'}</penalty_points>
    \t\t\t\t</Competitor>
    \t\t\t</CC_notranked>`;
        return xmlString;
        };

        // make xml string for each racer
        const xmlStrings = [];
        data.forEach((row, idx) => {
            if (row["status"] === "") {
                row["status"] = "QLF";
            }
            // if the racer does not have a first or last name dont do this
            if (
                row["firstname"] !== undefined &&
                row["lastname"] !== undefined &&
                row["status"] === "QLF"
            ) {
                const xmlString = makeSingleRacerString(row, idx);
                xmlStrings.push(xmlString);
            }
        });

        // make xml string for all racers
        const xmlString = `\t\t<CC_classified>
        ${xmlStrings.join("\n")}
    \t\t</CC_classified>`;

        // clear the xmlStrings array
        xmlStrings.length = 0;

        data.forEach((row) => {
        if (
            row["firstname"] !== undefined &&
            row["lastname"] !== undefined &&
            row["status"] !== "QLF"
        ) {
            const xmlString = makeSingleRacerStringNotQLF(row);
            xmlStrings.push(xmlString);
        }
        });

        const xmlString2 = `\n\n\t\t<CC_notclassified>
        ${xmlStrings.join("\n")}
    \t\t</CC_notclassified>`;

        return xmlString + "\n" + xmlString2;
    };
        // xml string for header
        const headerString = makeHeaderString();
        const raceInfoString = makeRaceInfoString();
        let racersString = ''
    
        racersString = makeRacersString();
        
    
        // compile all strings into one
        var xmlString =
            `<?xml version="1.0" encoding="UTF-8"?>\n<!-- Created by Zone4 Systems www.zone4.ca -->\n<Fisresults>\n` +
            headerString +
            "\n" +
            "\t<CC_race>\n" +
            raceInfoString +
            "\n" +
            racersString +
            "\n" +
            "\t</CC_race>\n" +
            "</Fisresults>";
        // console.log('formated XML:', formatXml(xmlString))
        xmlString = formatXml(xmlString)
        const xmlBlob = new Blob([xmlString], { type: "text/xml" });
        const xmlUrl = URL.createObjectURL(xmlBlob);

        setxmlURL(xmlUrl);

    return null
}