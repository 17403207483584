import React from "react";
import "./css/CalculatePoints.css";

function CalculatePoints(props) {
    const headerInfo = props.headerInfo;
    const setHeaderInfo = props.setHeaderInfo;
    const data = props.csvData;
    const setCsvData = props.setCsvData;
    const ToggleCalculatePenalty = props.ToggleCalculatePenalty;
    const parentElement = props.parentElement
    const top5HeadersSubset = ['rank', 'time', 'firstname', 'lastname', 'FIScode', 'USSAcode', 'FISpoints', 'NRLpoints', 'Country', 'Race Points']

    // helper for CalculatePenalty
    function roundNumber(number) {
        const roundedNumber = Math.floor(number * 1000 + 0.5) / 1000;
        return roundedNumber.toFixed(2);
    }

    // helper for Calculate FIS Points
    const convertTimeToSeconds = (time) => {
        // convert time to seconds
        // time is in the format hh:mm:ss.ms
        // const [hours, minutes, seconds] = time.split(':');
        // const totalSeconds = parseFloat((parseFloat(hours) * 60 * 60) + (parseFloat(minutes) * 60) + (parseFloat(seconds)));
        // return totalSeconds;
        const timeParts = time.split(':');
    
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
      
    
        if (timeParts.length === 3) {
            console.log('timeParts3: ' + timeParts)
            hours = parseFloat(timeParts[0]);
            minutes = parseFloat(timeParts[1]);
            seconds = parseFloat(timeParts[2]);
            //const secondsParts = timeParts[2].split('.');
            //seconds = parseFloat(secondsParts[0]);
            //milliseconds = parseFloat(secondsParts[1] || 0);
        } else if (timeParts.length === 2) {
            console.log('timeParts2: ' + timeParts)
            minutes = parseFloat(timeParts[0]);
            seconds = parseFloat(timeParts[1]);
            //const secondsParts = timeParts[1].split('.');
            //seconds = parseFloat(secondsParts[0]);
            //milliseconds = parseFloat(secondsParts[1] || 0);
        } else {
            throw new Error('Invalid time format. Expected hh:mm:ss.ms or mm:ss.ms');
        }
    
        //const totalSeconds = (hours * 3600) + (minutes * 60) + seconds + (milliseconds / 10);
        const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
        
        return totalSeconds;
    }

    // calculate the penalty 
    const calculatePenalty = () => {
        let top5Racers = [...headerInfo.top5Racers]

        // go through each person and look to see if they hvae points. if they dont then add the defult ones
        if(parentElement === 'ussa'){
            top5Racers.forEach((racer) => {
                if((!racer['FISpoints'] && !racer['NRLpoints']) || (!racer['FISpoints'] && (racer['NRLpoints'] === 990 || racer['NRLpoints'] === '990'))){
                    const currentDate = new Date()
                    let currentYear = currentDate.getFullYear();
                    const currentMonth = currentDate.getMonth();
                   
                    // if the month is greater then sept. then add a year to get the corect season
                    if(currentMonth < 8) {
                        currentYear--
                    }

                    let racerYOB = racer['YOB']
                    const age = currentYear - racerYOB;
                    
                    if(age < 16){
                        racer['NRLpoints'] = 350
                    } else{
                        // they are older then 16 (not in U16)
                        if(headerInfo.sex === 'M'){
                            racer['NRLpoints'] = 200
                        } else {
                            racer['NRLpoints'] = 250
                        } 
                    }
                }
            })
        }

        console.log('top 5 racers for penalty calculation:', top5Racers)

        // sort the top 5 racers by lowest fis points
        const sortedPeople = parentElement === 'ussa' ? (
            top5Racers.sort((a, b) => {
                const aPoints = (a["NRLpoints"] && a["NRLpoints"] !== 990) ? a["NRLpoints"] : a["FISpoints"];
                const bPoints = (b["NRLpoints"] && b["NRLpoints"] !== 990) ? b["NRLpoints"] : b["FISpoints"];
                return aPoints - bPoints;
            })
        ) : (
            top5Racers.sort(
                (a, b) => a["FISpoints"] - b["FISpoints"]
            )
        );

        console.log('top 5 racers for penalty calculation after being sorted:', sortedPeople)

        let racer1 = null
        let racer2 = null
        let racer3 = null

        // if the parent element is the ussa point calcualtion then take into consideration the nrl points. if not then dont. 
        if(parentElement === 'ussa'){
            racer1 = parseFloat(
                (sortedPeople[1]["NRLpoints"] && sortedPeople[1]["NRLpoints"] !== 990) 
                    ? sortedPeople[1]["NRLpoints"] 
                    : sortedPeople[1]["FISpoints"]
            );

            racer2 = parseFloat(
                (sortedPeople[2]["NRLpoints"] && sortedPeople[2]["NRLpoints"] !== 990) 
                    ? sortedPeople[2]["NRLpoints"] 
                    : sortedPeople[2]["FISpoints"]
            );

            racer3 = parseFloat(
                (sortedPeople[3]["NRLpoints"] && sortedPeople[3]["NRLpoints"] !== 990) 
                    ? sortedPeople[3]["NRLpoints"] 
                    : sortedPeople[3]["FISpoints"]
            );
        } else {
            racer1 = parseFloat(sortedPeople[1]["FISpoints"])
            racer2 = parseFloat(sortedPeople[2]["FISpoints"])
            racer3 = parseFloat(sortedPeople[3]["FISpoints"])
        }

        console.log('racer1:', racer1, 'racer2:', racer2, 'racer3:', racer3)

        let mid3FISPoints = racer1 + racer2 + racer3

        mid3FISPoints = mid3FISPoints / 3.5;

        // round to the nearest 0.5
        var roundedFISPoints = roundNumber(mid3FISPoints);
        console.log('penalty calculated:', roundedFISPoints)
        
        setHeaderInfo({
            ...headerInfo,
            calculatedPenalty: roundedFISPoints,
            penalty: roundedFISPoints
            });
    }

    // calclates FIS race points for each racer
    const calculateFISPoints = () => {
        console.log("Calculating POINTS!!!")
        // calculate the fis race points for the data
        // RacePoints P = Round(((Tx / To) - 1) * FFactor + Applied_Penalty

        // must have f factor 
        if (headerInfo.sugestedFFactor === 0) {
            window.alert("must have f factor");
        }
        else {
            var RacePoints = 0;
            var FFactor = headerInfo.sugestedFFactor;
            // var Applied_Penalty = parseFloat(headerInfo.penalty)
            var Tx = 0;
            var To = convertTimeToSeconds(data[0]["time"]);
        
            // for each racer go through the data and calculate the race points and set them in the data
            let newData = [...data];
            newData.forEach((row) => {
                if(row["status"] === "" && row['time'] !== ""){
                    // calculate points for racer by setting Tx to time of racer in seconds and To to time of winner in seconds
                    Tx = row["time"];
                    Tx = convertTimeToSeconds(Tx);

                    row["Race Points"] = RacePoints;
                    console.log('tx:', Tx, 'To:', To)
                    let tmpRacePoints = (((Tx / To) - 1.0) * FFactor);
                    //let penaltyToAdd = (headerInfo.category === 'WSC' || headerInfo.category === 'OWG') ? (0) : ((parseFloat(headerInfo['penalty'])).toFixed(2));
                    //tmpRacePoints = tmpRacePoints //+ parseFloat(penaltyToAdd);
                    //console.log('penalty to add:', penaltyToAdd);
                    RacePoints = roundNumber(tmpRacePoints);
                    console.log(`RacePoints for ${row["rank"]} after rounding:`, RacePoints);
                    row['Race Points'] = RacePoints;
                }
                else{
                    RacePoints = null;
                }
            });
            console.log('new data:', newData)
            setCsvData(newData);
            //   if the headers do not include Race Points then add it
            if(!headerInfo.headers.includes('Race Points')){
                setHeaderInfo({
                    ...headerInfo,
                    headers: [...headerInfo.headers, 'Race Points']
                })
            }
        }
    }

    return (
        // {/* display penalty/fis points popup */}
        <div id="overlay">
            <div id="PenaltyPopup">
                <div id="PenaltyBox">
                    <h2>Race Points</h2>
                    <div id="calcPenality">
                        <button disabled={!data || (data.length === 0)} onClick={calculatePenalty}>
                            Calculate Penalty
                        </button>
                    </div>
                    <div id="penaltyBoxNumbers">
                        <div className="input-row">
                            <label htmlFor="CalculatedPenalty">Penalty:</label>
                            <input
                                id="CalculatedPenalty"
                                type="number"
                                value={headerInfo.calculatedPenalty || null}
                                onChange={(event) => {
                                    setHeaderInfo({
                                        ...headerInfo,
                                        calculatedPenalty: parseFloat(event.target.value) || 0,
                                        penalty: parseFloat(event.target.value) || 0
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="input-row">
                        <label htmlFor="f-factor">F-Factor:</label>
                        <select
                            id="f-factor"
                            value={headerInfo.sugestedFFactor || ''}
                            onChange={(event) => {
                                setHeaderInfo({
                                    ...headerInfo,
                                    sugestedFFactor: parseFloat(event.target.value) || 0
                                });
                            }}
                        >
                            <option value="800">800 - Interval Start</option>
                            <option value="1200">1200 - Sprint and Pursuit</option>
                            <option value="1400">1400 - Mass Start and Skiathlon</option>
                            <option value="2800">2800 - FIS long distance popular competitions</option>
                        </select>
                    </div>

                    <div id="buttonBox">
                        <button disabled={!data || (data.length === 0)} onClick={calculateFISPoints}>
                            Assign Race Points
                        </button>
                    </div>
                </div>

                {/* display racers in the results with the lowest fis points in data */}

                <div id="Top5Racers">
                    <h2>Top 5 Racers</h2>
                    <table id="top5racerstable">
                        <thead>
                            <tr key={'headers1'}>
                                {/* {headerInfo.headers && headerInfo.headers.map((header, idx) => (
                                    <th key={`${header}${idx}th`}>{header}</th>
                                ))} */}
                                {top5HeadersSubset && top5HeadersSubset.map((header, idx) => (
                                    <th key={`${header}${idx}th`}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {headerInfo.top5Racers && (() => {
                                // Sort the racers based on the points criteria
                                const sortedRacers = [...headerInfo.top5Racers].sort((a, b) => {
                                    const aPoints = (a["NRLpoints"] && a["NRLpoints"] !== 990) ? a["NRLpoints"] : a["FISpoints"];
                                    const bPoints = (b["NRLpoints"] && b["NRLpoints"] !== 990) ? b["NRLpoints"] : b["FISpoints"];
                                    return aPoints - bPoints;
                                });

                                // Determine the indices of the middle three racers
                                const middleIndices = [1, 2, 3]; // Assuming sortedRacers is sorted, these are the middle three

                                return headerInfo.top5Racers.map((row, idx) => {
                                    // Find the index of the current row in the sorted list
                                    const sortedIndex = sortedRacers.findIndex(sortedRow => sortedRow === row);
                                    const isMiddleRacer = middleIndices.includes(sortedIndex);

                                    return (
                                        <tr key={`row-${idx}`}>
                                            {top5HeadersSubset.map((header, idx2) => {
                                                const isHighlighted = isMiddleRacer && (
                                                    (header === "NRLpoints" && row["NRLpoints"] && row["NRLpoints"] !== 990) ||
                                                    (header === "FISpoints" && (!row["NRLpoints"] || row["NRLpoints"] === 990))
                                                );

                                                return (
                                                    <td key={`cell-${idx2}-${idx}`} className={isHighlighted ? 'highlight' : ''}>
                                                        {row[header]}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                });
                            })()}
                        </tbody>
                    </table>
                </div>
                <div className="Submit-Button-Box">
                    <button onClick={ToggleCalculatePenalty}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default CalculatePoints;
