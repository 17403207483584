import React, { useEffect, useState } from "react";
import './css/NewCSVData.css';

function NewCSVData(props){
    const [dataToShow, setDataToShow] = useState([]);

    const isPopupOpen = props.isPopupOpen;
    const headers = props.headerInfo.headers;
    const headerInfo = props.headerInfo;
    const oldData  = props.oldData;
    const newData = props.newData || [];
    const closeWindow = props.closeWindow;
    const setCsvData = props.setCsvData;

    const acceptChanged = () =>{
        console.log('trying to set csv data with:', newData)
        // update the csv data with the new data
        setCsvData(JSON.parse(JSON.stringify(newData)))
        closeWindow()
    }

    const declineChanged = () =>{
        // update the csv data with the old data
        setCsvData(oldData)
        closeWindow()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        console.log('30: new data:', newData)
        console.log('31: old data:', oldData)
        // compaire the old data and the new data and anywhere where the new data does not match the old data. then add a class to the item to show that it has been changed
        let updatedData = []
        if(newData.length < 1){return}
        if(!oldData){return}
        let j = 0
        for( let i = 0; i < oldData.length; i++){
            let oldRow = oldData[i]
            let newRow = newData[j]
            let updatedRow = {}

            // check it is the same person
            if(oldRow && ((oldRow["FIScode"] && oldRow["FIScode"] === newRow["FIScode"]) || (oldRow["USSAcode"] && oldRow["USSAcode"] === newRow["USSAcode"]))){
                // it is the same person
                for (const header in newRow) {
                    let newVallue = typeof newRow[header] === 'string' ? (newRow[header].toLowerCase()) : (newRow[header])
                    let oldVallue = typeof oldRow[header] === 'string' ? (oldRow[header].toLowerCase()) : (oldRow[header])

                    if(newVallue !== oldVallue && header !== 'rank'){
                        updatedRow[header] = [newRow[header], 'green']
                    }
                    else{
                        updatedRow[header] = [newRow[header], 'normal']
                    }
                }
                j++ // increment to the next peron in the new data
                if (j >= newData.length) {
                    break; // Exit the loop to avoid accessing out-of-bounds index
                }
            } else{
                // its not the same person. cross them out
                for (const header in newRow) {
                    updatedRow[header] = [oldRow[header], 'crossed-out']
                    
                    //if it's a crossed out row, AND there it's the FIScode column AND there's a FIS code there, special case highlight red
                    if(header === "FIScode" && updatedRow[header][0] !== ""){
                        console.log('updatedRow[header]', )
                        updatedRow[header] = [oldRow[header], 'red']
                    }
                }
            }

            updatedData.push(updatedRow)
        }

        setDataToShow(updatedData)
    },[props.newData])

    return(
        <>
        <div id="popup-csvData" 
            style={{display: isPopupOpen ? 'flex' : 'none'}}
        >
            <div className='button-bar'>
                <button onClick={acceptChanged}>Accept</button>
                <button onClick={declineChanged}>Decline</button>
            </div>

            <div id='table-wraper'>
                {newData && newData.length !== 0 ? (
                    <table>
                        <thead>
                            <tr id='headers'>
                                {headers.map((header, index) => (
                                <th key={`header-${header}-${index}`}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                        {dataToShow.map((row, idx) => (
                            <tr key={`row-${idx}`}>
                            {headers.map((header, index) => (
                                <td 
                                    key={`cell-${idx}-${index}`}
                                    className={
                                        (header === 'firstname' || header === 'lastname' ? 'name' : '') 
                                        + ' ' +
                                        (row[header] && row[header][1] ? row[header][1] : '')
                                    }
                                >
                                    {
                                        (row[header] && row[header][0] && String(row[header][0]) !== 'NaN')  ? 
                                            (
                                                (header === 'Race Points' && String(row[header][0]) !== 'NaN' && !isNaN(parseFloat(row[header][0])) && !isNaN(parseFloat(headerInfo['penalty']))) ? 
                                                    (parseFloat(row[header][0]) + parseFloat(headerInfo['penalty'])).toFixed(2) :
                                                    (String(row[header][0]))
                                            ) :
                                            (`\u00a0`)
                                    }
                                </td>
                            ))}
                            </tr>
                        ))}
                        </tbody> 
                    </table>
                ) : (
                    oldData && oldData.length === 0 ? (
                        <h4>No Data Yet. Import a File.</h4>
                    ):(
                        <h4>No Racers in the File Have Valid FIS or USSA Codes</h4>
                    )
                )}
            </div>
        </div>
        <div className='rendering-test'>rendering_test_csv_data</div>
        </>
    )
}

export default NewCSVData;